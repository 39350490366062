import React from "react";
import "./OverviewSection3.css";
import EnvironmentalBenefits from "./EnvironmentalBenefits";
import HistoricalProduction from "./HistoricalProduction";

function OverviewSection3() {
  return (
    <div className="overviewsection3">
      <div className="overviewsection3-container">
        <EnvironmentalBenefits />
        <HistoricalProduction />
      </div>
    </div>
  );
}

export default OverviewSection3;
