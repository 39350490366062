import 'rsuite/DateRangePicker/styles/index.css';
import DateRangePicker from 'rsuite/DateRangePicker';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { IconButton } from '@mui/material';


function MuiDateRangePicker ({ok,clean}) {
  function Styles() {
    return <style>{`         
.custom-date-range .rs-input-group {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: 4px !important;
}
.rs-input {
  padding: 0px 0px !important;
  text-align: center !important;
  font-size: 17px !important;
  height: 27px !important;
}
.rs-input::placeholder {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 16px;
  font-weight: 400;
  opacity: 1 !important;
}
.rs-input-group-addon {
  height: 27px !important;
  padding: 0px 0px !important;
  font-size: 14px !important;
}
                 `}</style>
  }

  const calButton = () => {
    return <IconButton
      sx={{ padding: 0 }}
    
    >
      <CalendarMonthOutlinedIcon style={{ fontSize: 27 }} />
    </IconButton>
  }

  return (
    <div>
      <Styles />
      <DateRangePicker  limitStartYear={6} limitEndYear={1} onOk={ok} className='custom-date-range' onClean={clean} placement='bottomEnd' ranges={[]} calendarSnapping editable={false} appearance="default" caretAs={calButton} showHeader={false} size='sm' placeholder="Date Range" style={{ width: 210 }} />
    </div>
  )

}

  export default MuiDateRangePicker;