import { Button, Card, Col, Container, Image, Row, Stack } from "react-bootstrap";
import { ReactComponent as GridIcon } from "./../../../assets/grid2_icon.svg";
import { ReactComponent as InfoIcon } from "./../../../assets/information_icon.svg";
import expandAltIcon from "./../../../assets/expand_alt_icon.svg"; // Adjust the path as necessary
import { SvgIcon } from "@mui/material";
import { useState } from "react";

function Styles() {
    return (<style>
        {`
     .row-bottom-border{
           background-color: #e4f0fc;
           border: 1px dotted;
           border-width: 0px 0px 1px 0px;
    }    
     .col-right-border{
           background-color: #e4f0fc;
          border: 1px dotted;
         border-width: 0px 1px 0px 0px;
    }    
         .card-text{
         color:#167db0;
         font-size:25px;
         font-weight:bold

         }
    
    `}
    </style>)
}


function ProductionState(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);


    return (<Card className=" rounded-3 shadow-sm h-100" >
        <Card.Header as={"div"} className="bg-transparent d-flex flex-row align-items-center justify-content-between border-bottom border-bottom-2 shadow-sm border-secondary-subtle">
            <div className="d-flex flex-row align-items-center justify-content-between">
                <GridIcon width={12} height={20} />
                <div className="production-title">Production</div>
                <InfoIcon width={20} height={20} />
            </div>
            {/* <SvgIcon component={expandAltIcon} /> */}
            <img
                src={expandAltIcon}
                alt="Expand"
                className="hp-expand-icon"
                onClick={() => setIsModalOpen(true)} // Open modal on click
            />
        </Card.Header>
        <Card.Body>
            <Styles />
            <Container className="h-100 d-flex flex-column align-items-center justify-content-evenly">
                <Row className="w-100 text-center row-bottom-border h-100">
                    <Col className="col-right-border align-content-center"><div className="card-text">1.55</div><span>kw</span></Col>
                    <Col className="col-right-border align-content-center"><div className="card-text">31.55</div><span>kwh</span></Col>
                    <Col className="card-block align-content-center"><div className="card-text">100.55</div><span>kw</span></Col>
                </Row>
                <Row className="w-100 text-center  h-100">
                    <Col className="col-right-border align-content-center fw-bold ">Peak Power</Col>
                    <Col className="col-right-border align-content-center fw-bold">Todays Production</Col>
                    <Col className="col-right-border align-content-center border-0 fw-bold">Current Power</Col>
                </Row>
            </Container>
            {/* Modal structure */}
            {isModalOpen && (
                <div className={`hp-modal open`}>
                    <div className="hp-modal-content">
                        <button
                            className="hp-modal-close"
                            onClick={() => setIsModalOpen(false)}
                        >
                            &times;
                        </button>
                        <div className="hp-modal-header">
                            <h2 className="hp-modal-title">Production</h2>
                        </div>
                        <div className="hp-modal-card-content">
                            <Container className="h-100 d-flex flex-column align-items-center justify-content-evenly">
                                <Row className="w-100 text-center row-bottom-border h-100">
                                    <Col className="col-right-border align-content-center"><div className="card-text">1.55</div><span>kw</span></Col>
                                    <Col className="col-right-border align-content-center"><div className="card-text">31.55</div><span>kwh</span></Col>
                                    <Col className="card-block align-content-center"><div className="card-text">100.55</div><span>kw</span></Col>
                                </Row>
                                <Row className="w-100 text-center  h-100">
                                    <Col className="col-right-border align-content-center fw-bold ">Peak Power</Col>
                                    <Col className="col-right-border align-content-center fw-bold">Todays Production</Col>
                                    <Col className="col-right-border align-content-center border-0 fw-bold">Current Power</Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            )}
        </Card.Body>
    </Card>

    )
}

export default ProductionState;
