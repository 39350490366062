
import "./Analytics.css";
import React, { useState, useEffect,useMemo } from "react";
import { Bar, CartesianGrid, ComposedChart, Label, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis, Text } from "recharts";
import NoDataIcon from "../../assets/no-data-icon.svg";
import SettingIcon from "../../assets/Setting-icon.svg"
import { HistoricalData } from "../../dataassets/GeneratedHistorical";
import { Button } from "@mui/material";
import { Card, Container, Dropdown, Form, OverlayTrigger, Popover, Row, Stack } from "react-bootstrap";
// import SolarNavbar from "../navbar/SolarNavbar";
import dayjs from "dayjs";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import lineChart from "../../assets/analytic_line_chart.png"
import barChart from "../../assets/analytic_bar_chart.png"
import MuiDateRangePicker from "../utilities/MuiDateRangePicker";
import MuiDatePickerComponent from "../plants/plantstatus/MuiDatePickerComponent";

function Styles() {
  return <style>{`
    .arrow-buttons {
  background-color: transparent;
  color: black;
  padding: 0px;
  border: none;
  margin: 2px;
}
.arrow-buttons:hover {
  background-color: transparent;
  color: lightgrey;
}
.ana-drop-down {
  border: 0px solid lightgrey;
  padding: 2px 4px 2px 4px;
  margin: 0px;
  border-radius: 4px;
  box-shadow: 0px 0px 1px 0px;
  font-size: small;
  top: auto;
  width: auto;
}
.ana-drop-menu {
  padding: 5px;
  margin: 0px;
  width: inherit !important;
  min-width: auto;
}
.overlay-body-popover {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0px;
  font-family: "Arial", sans-serif;
  font-size: 16px;
  width: 160px;
}

.drop-menu {
  font-size: small;
  margin: 0px 1px 0px 1px !important;
  font-weight: bold;
  align-items: center;
  justify-content: left;
  display: flex;
  text-wrap: nowrap;
}
.drop-menu input {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.chart-types {
  padding: 0px;

  text-align: left;
  width: 100%;
  padding: 0px 5px 0px 5px;
  background-color: #ffffff;
  color: black;
}

.chart-types-active {
  padding: 0px;
  margin: 0px;
  text-align: left;
  width: 100%;
  padding: 0px 5px 0px 5px;
  background-color: #708496;
  color: white;
}

.chart-types:hover {
  background-color: #708496 !important;
  color: white;
}

  `}

  </style>
}

const IndividualAnalytic = () => {
  const [view, setView] = useState("Daily");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [checkedOptions, setCheckedOptions] = useState([]);
  const [data, setData] = useState([]);
  const [chartType, setChartTypes] = useState('Bar');
  const [chartOption, setChartOption] = useState('year'); // Default to "year
  const [xLabel, setxLabel] = useState('');
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [update, setUpdate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const chartTypes = ["Daily", "Monthly", "Yearly", "Lifetime", 'Custom'];
  const selectColumns = ["Production Power", "Output Energy", "Peak Power", "Normalized Power", "PR", "CUF", "Irradiance", "Max Temprature"]

  useEffect(() => {

  }, [view, selectedDate, checkedOptions, chartType, chartOption]);

  function customDateSelection(cDates) {
    setFromDate(cDates[0])
    setToDate(cDates[1])
    setUpdate(!update)
  }
  function customDateClear() {
    setFromDate(null)
    setToDate(null)
    setUpdate(!update)
  }

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
    const year = newValue.year();
    if (view === 'Yearly' && newValue) {
      setxLabel('Year ' + year);
    } else if (view === 'Monthly' && newValue) {
      setxLabel(newValue.format('MMMM') + ' ' + year);
    } else if (view === 'Custom' && newValue) {
      setxLabel(newValue.format(''))
    }
  };

  const chartOptionsChange = (v) => {
    //setChartOption(event.target.value);
    setView(v)
    if (selectedDate != null) {
      handleDateChange(selectedDate);
    }
  };

  //calculating date ordinals
  const nth = (d) => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  };

  const getOpenToView = () => {
    // if(chartOption === 'week') return  'week'
    if (chartOption === 'Year') return 'year';
    if (chartOption === 'Month') return 'month';
    return 'day'; // Default to 'day' if needed
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    // If checkbox is checked and the number of selected options is less than 2, add it to the list
    if (checked && checkedOptions.length < 2) {
      setCheckedOptions([...checkedOptions, name]);
    }
    // If the checkbox is unchecked, remove it from the list
    else if (!checked) {
      setCheckedOptions([...checkedOptions.filter((option) => option !== name)]);
    }
  };

  let filteredData = useMemo(() => {
    if (view === "Daily") {
      const date = selectedDate.get('date');
      const month = selectedDate.get('month');
      const year = selectedDate.get('year');
      return HistoricalData.filter((item) => {
        const lDate = new Date(item.datetime);
        return (lDate.getDate() === date && lDate.getMonth() === month && lDate.getFullYear() === year)
      })
      .sort((a,b)=>dayjs(a.datetime).diff(b.datetime) >= 0 ? 1 : -1)
        .reduce((acc, item) => {
          let hm = dayjs(item.datetime).format('LT');
          let index = acc.findIndex(k => k.name === hm);
          if (index === -1) {//.includes({ name: hm })) {
            acc.push({
              name: hm,
              "Production Power": parseFloat(item["Ac output"]) || 0,
              "Irradiance": parseFloat(item.irradiance) || 0,
              "Output Energy": parseFloat(item["output Energy"]) || 0,
              "Peak Power": parseFloat(item.peak_power) || 0,
              "Normalized Power": parseFloat(item.Normalized_power) || 0,
              "PR": parseFloat(item.PR) || 0,
              "CUF": parseFloat(item.CUF) || 0,
              "Max Temprature": parseFloat(item["max temp"]) || 0,

            })
          } else {
            acc[index]["Production Power"] += parseFloat(item["Ac output"]) || 0,
              acc[index]["Irradiance"] += parseFloat(item.irradiance) || 0,
              acc[index]["Output Energy"] += parseFloat(item["output Energy"]) || 0
            acc[index]["Peak Power"] += parseFloat(item.peak_power) || 0,
              acc[index]["Normalized Power"] += parseFloat(item.Normalized_power) || 0,
              acc[index]["PR"] += parseFloat(item.PR) || 0
            acc[index]["CUF"] += parseFloat(item.CUF) || 0
            acc[index]["Max Temprature"] += parseFloat(item["max temp"]) || 0
          }
          return acc;
        }, [])

    }  else if (view === "Monthly") {
      const month = selectedDate.get('month');
      const year = selectedDate.get('year')
      return HistoricalData
        .filter((item) => {
          const date = dayjs(item.datetime);
          return (month === date.get('month') && year === date.get('year'))
        })
        .reduce((acc, item) => {
          let hm = dayjs(item.datetime).get("D");
          let index = acc.findIndex(k => k.name === hm);
          if (index === -1) {//.includes({ name: hm })) {
            acc.push({
              name: hm,
              "Production Power": parseFloat(item["Ac output"]) || 0,
              "Irradiance": parseFloat(item.irradiance) || 0,
              "Output Energy": parseFloat(item["output Energy"]) || 0,
              "Peak Power": parseFloat(item.peak_power) || 0,
              "Normalized Power": parseFloat(item.Normalized_power) || 0,
              "PR": parseFloat(item.PR) || 0,
              "CUF": parseFloat(item.CUF) || 0,
              "Max Temprature": parseFloat(item["max temp"]) || 0,

            })
          } else {
            acc[index]["Production Power"] += parseFloat(item["Ac output"]) || 0,
              acc[index]["Irradiance"] += parseFloat(item.irradiance) || 0,
              acc[index]["Output Energy"] += parseFloat(item["output Energy"]) || 0
            acc[index]["Peak Power"] += parseFloat(item.peak_power) || 0,
              acc[index]["Normalized Power"] += parseFloat(item.Normalized_power) || 0,
              acc[index]["PR"] += parseFloat(item.PR) || 0
            acc[index]["CUF"] += parseFloat(item.CUF) || 0
            acc[index]["Max Temprature"] += parseFloat(item["max temp"]) || 0
          }
          return acc;
        }, []).sort((a, b) =>  a.name - b.name)
    } else if (view === "Yearly") {
      return HistoricalData.filter((d) => selectedDate.diff(d.datetime, 'y') === 0)
      .sort((a, b) =>  dayjs(a.datetime).get('month')-dayjs(b.datetime).get('month'))
        .reduce((acc, item) => {
          let hm = dayjs(item.datetime).format('MMM');
          let index = acc.findIndex(k => k.name === hm);
          if (index === -1) {//.includes({ name: hm })) {
            acc.push({
              name: hm,
              "Production Power": parseFloat(item["Ac output"]) || 0,
              "Irradiance": parseFloat(item.irradiance) || 0,
              "Output Energy": parseFloat(item["output Energy"]) || 0,
              "Peak Power": parseFloat(item.peak_power) || 0,
              "Normalized Power": parseFloat(item.Normalized_power) || 0,
              "PR": parseFloat(item.PR) || 0,
              "CUF": parseFloat(item.CUF) || 0,
              "Max Temprature": parseFloat(item["max temp"]) || 0,

            })
          } else {
            acc[index]["Production Power"] += parseFloat(item["Ac output"]) || 0,
              acc[index]["Irradiance"] += parseFloat(item.irradiance) || 0,
              acc[index]["Output Energy"] += parseFloat(item["output Energy"]) || 0
            acc[index]["Peak Power"] += parseFloat(item.peak_power) || 0,
              acc[index]["Normalized Power"] += parseFloat(item.Normalized_power) || 0,
              acc[index]["PR"] += parseFloat(item.PR) || 0
            acc[index]["CUF"] += parseFloat(item.CUF) || 0
            acc[index]["Max Temprature"] += parseFloat(item["max temp"]) || 0
          }
          return acc;
        }, [])
    } else if (view === "Lifetime") {
      return HistoricalData
      .sort((a, b) =>  dayjs(a.datetime).get('year')-dayjs(b.datetime).get('year'))
      .reduce((acc, item) => {
        let hm = dayjs(item.datetime).format('YYYY');
        let index = acc.findIndex(k => k.name === hm);
        if (index === -1) {//.includes({ name: hm })) {
          acc.push({
            name: hm,
            "Production Power": parseFloat(item["Ac output"]) || 0,
            "Irradiance": parseFloat(item.irradiance) || 0,
            "Output Energy": parseFloat(item["output Energy"]) || 0,
            "Peak Power": parseFloat(item.peak_power) || 0,
            "Normalized Power": parseFloat(item.Normalized_power) || 0,
            "PR": parseFloat(item.PR) || 0,
            "CUF": parseFloat(item.CUF) || 0,
            "Max Temprature": parseFloat(item["max temp"]) || 0,

          })
        } else {
          acc[index]["Production Power"] += parseFloat(item["Ac output"]) || 0,
            acc[index]["Irradiance"] += parseFloat(item.irradiance) || 0,
            acc[index]["Output Energy"] += parseFloat(item["output Energy"]) || 0
          acc[index]["Peak Power"] += parseFloat(item.peak_power) || 0,
            acc[index]["Normalized Power"] += parseFloat(item.Normalized_power) || 0,
            acc[index]["PR"] += parseFloat(item.PR) || 0
          acc[index]["CUF"] += parseFloat(item.CUF) || 0
          acc[index]["Max Temprature"] += parseFloat(item["max temp"]) || 0
        }
        return acc;
      }, [])
    } else if (view === "Custom" && fromDate !== null && toDate !== null) {
      return HistoricalData.filter((item) => dayjs(item.DATE).isBetween(fromDate, toDate))
      .sort((a, b) =>  dayjs(a.datetime).diff(b.datetime) >= 0 ? 1 : -1 )
        .reduce((acc, item) => {
          let hm = dayjs(item.DATE).format('DD MMM YYYY');
          let index = acc.findIndex(k => k.date === hm);
          if (index === -1) {
            acc.push({
              name: hm,
              "Production Power": parseFloat(item["Ac output"]) || 0,
              "Irradiance": parseFloat(item.irradiance) || 0,
              "Output Energy": parseFloat(item["output Energy"]) || 0,
              "Peak Power": parseFloat(item.peak_power) || 0,
              "Normalized Power": parseFloat(item.Normalized_power) || 0,
              "PR": parseFloat(item.PR) || 0,
              "CUF": parseFloat(item.CUF) || 0,
              "Max Temprature": parseFloat(item["max temp"]) || 0,

            })
          } else {
            acc[index]["Production Power"] += parseFloat(item["Ac output"]) || 0,
              acc[index]["Irradiance"] += parseFloat(item.irradiance) || 0,
              acc[index]["Output Energy"] += parseFloat(item["output Energy"]) || 0
            acc[index]["Peak Power"] += parseFloat(item.peak_power) || 0,
              acc[index]["Normalized Power"] += parseFloat(item.Normalized_power) || 0,
              acc[index]["PR"] += parseFloat(item.PR) || 0
            acc[index]["CUF"] += parseFloat(item.CUF) || 0
            acc[index]["Max Temprature"] += parseFloat(item["max temp"]) || 0
          }
          return acc;
        }, [])
    } else {
      return [];
    }
  }, [data, view, selectedDate, update])

  const getDisplayContent = (props) => {
    return (
      <>
        {checkedOptions.length > 1 ? <Text x={-150} y={30} fontWeight={"bold"} fontSize={16} fill="#90b7e5" transform={`rotate(-90)`} textAnchor="middle">
          {checkedOptions[0] === "Irradiance" ? `${checkedOptions[0]} in KW /sqm` : `${checkedOptions[0]} in KWH`}
        </Text> : ""}
        {checkedOptions.length === 2 ? <Text x={-150} y={15} fontWeight={"bold"} fontSize={16} fill="#1ead61" transform={`rotate(-90)`} textAnchor="middle">
          {checkedOptions[1] === "Irradiance" ? `${checkedOptions[1]} in KW /sqm` : `${checkedOptions[1]} in KWH`}
        </Text> : ""}
      </>)
  }

  const getDisplayContentWithOutDaily = (props) => {
    return <Text x={-150} y={20} fontSize={16} fill="black" fontWeight={"bold"} transform={`rotate(-90)`} textAnchor="middle">
      Production in KWH
    </Text>
  }

  const renderNoData = () => (
    <div className="hp-no-data-container">
      <img
        src={NoDataIcon}
        alt="No Data Available"
        className="hp-no-data-icon"
      />
      <p>No Data Available</p>
    </div>
  );

  const handleLineChartTypeChange = () => {
    setChartTypes("Line")
  }

  const handleBarChartTypeChange = () => {
    setChartTypes('Bar')
  }

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <Stack direction="horizontal" className="justify-content-end" gap={2}>
        {
          payload.map((entry, index) => (
            <div className="d-flex flex-row gap-1 align-items-center  " key={entry.dataKey} ><div style={{ width: "20px", height: "15px", backgroundColor: `${entry.color}`, color: 'black', border: '1px solid grey', borderRadius: '3px' }} /><span style={{ fontSize: '12px', fontWeight: "bold" }} >{view === "Daily" ? entry.value : "Total Energy"}</span></div>
          ))
        }
      </Stack>
    );
  }

  const renderData = () => {
    if (filteredData.length === 0) {
      return renderNoData();
    }
  
    return <ComposedChart
      data={filteredData}
      margin={{ top: 10, right: 30, left: 10, bottom: 40 }} // Reduced the left margin to 20px
    >
      <CartesianGrid syncWithTicks vertical={false} strokeDasharray="4 4" strokeOpacity={0.5} />
      <XAxis dataKey="name" axisLine={false}
        tickLine={false} strokeOpacity={0.1} padding={{ left: 10 }} tick={{ fontSize: 11 }}>
        <Label
          value="TimeLine"
          offset={-5} // Adjust as needed
          position="insideBottom"
          style={{
            fontSize: "0.9rem",
            fill: "#333",
            textAnchor: "middle",
            fontFamily: "sans-serif",
            fontWeight: 600,
          }}
        />
      </XAxis>
      <YAxis axisLine={false}
        tickLine={false} padding={{ bottom: 5 }} strokeOpacity={0.01} tick={{ fontSize: 11 }}>
        <Label
          content={view === "Daily" ? getDisplayContent : getDisplayContentWithOutDaily}
          angle={-90}
          position="insideLeft"
          offset={10} // Adjust as needed
          style={{
            fontSize: "1.0rem",
            fill: "#333",
            textAnchor: "middle",
            fontFamily: "sans-serif",
            fontWeight: 600,
          }}
        />
      </YAxis>
      <Legend offset={10}
        align="right"
        wrapperStyle={{
          top: -20,
          right: 0,
          left: 0,
          color: "#23453e",
          borderRadius: 20
        }}
        content={renderLegend}
        iconType="square"
        verticalAlign="top" />
      <Tooltip
        cursor={false}
        formatter={(value, nameType) =>
          typeof value === "number" ? `${value.toFixed(2) + `${nameType === "Irradiance" ? ' KW / sqm' : ' KWH'}`}` : "N/A"
        }
        contentStyle={{
          backgroundColor: "#eee",
          color: "#333",
          borderRadius: "5px",
          fontSize: "12px",
        }}
        labelStyle={{ color: "#333" }}
      />
      {view !== 'Daily' ? <Bar dataKey="Production Power" fill={getChartColors()} barSize={30} radius={[4, 4, 4, 4]} /> : ''}
      {checkedOptions.length > 0 && view === 'Daily' ? <Line dataKey={checkedOptions[0]} fill="#90b7e5" stroke="#90b7e5" strokeWidth={2} /> : ''}
      {checkedOptions.length > 1 && view === 'Daily' ? <Line dataKey={checkedOptions[1]} fill="#1ead61" stroke="#1ead61" strokeWidth={2} /> : ''}
    </ComposedChart>
  };

 const getChartColors = () => {
    switch (view) {
      case 'Monthly': return '#1ead61';
      case 'Daily': return '#90b7e5';
      case 'Weekly': return '#25a4dd';
      case 'Yearly': return '#ffc90d';
      case 'Lifetime': return '#c0a6d9';
      case 'Custom': return '#1ead61';
      default:
        break;
    }
  }


  return (
    <div className="d-flex flex-column align-items-center justify-content-center analytics">
      {/* <SolarNavbar /> */}
      <Container className=" mw-100">
        <div className="m-2"><span className=" fs-4">Analytics</span></div>
        <Row className="ml-10 mr-10 mt-3 m-1">
          <Card className=" rounded-3 shadow-sm h-95" >
            <Card.Header as={"div"} className="bg-transparent d-flex flex-row align-items-center justify-content-between border-bottom border-bottom-0  border-secondary-subtle">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div className=" fs-6 fw-medium">  {view} Charts
                  <OverlayTrigger rootClose trigger="click" placement="bottom-end" overlay={
                    <Popover>
                      <Popover.Body className="overlay-body m-0 p-0">
                        <Container className="w-100 p-0 m-0">
                          <Stack direction="vertical" className="bg-white m-0 mt-1 p-0 overlay-body">
                            {chartTypes.map((v) => <Button onClick={() => chartOptionsChange(v)}
                              className={view === v ? "chart-types-active" : "chart-types"}
                              sx={{
                                margin: "1px 0px 1px 0px",
                                textAlign: "left",
                                width: "100%",
                                padding: "0px 5px 0px 5px",
                                textTransform: "capitalize",
                                backgroundColor: "white",
                                color: "black",

                                "&.chart-types:hover": {
                                  backgroundColor: "#708496",
                                  color: "white !important",
                                },
                                "&.chart-types-active": {
                                  backgroundColor: "#708496 !important",
                                  color: "white !important",
                                }
                              }} as="button">{v} Charts</Button>)}
                          </Stack>
                        </Container>
                      </Popover.Body>
                    </Popover>
                  }>
                    <KeyboardArrowDownOutlinedIcon />
                  </OverlayTrigger>
                </div>

              </div>
              <div className="d-flex flex-row align-items-center justify-content-between fs-6 fw-medium">
                <Styles />
                  <div className="d-flex flex-row">
                  {view === "Custom" ?
                    <MuiDateRangePicker ok={customDateSelection} clean={customDateClear} /> : <MuiDatePickerComponent
                      value={selectedDate}
                      onChange={handleDateChange}
                      views={view === 'Yearly' ? ['year'] : view === 'Monthly' ? ['year', 'month'] : ['day']}
                      openTo={getOpenToView()}
                      disabled={view === 'Lifetime'} />
                  }
                </div>
                <div className="hp-divider"></div>
                {view === 'Daily' ? <div>
                  <span className="m-1 ">Edit Widget</span>
                  <OverlayTrigger rootClose trigger="click" placement="bottom-end" overlay={
                    <Popover>
                      <Popover.Body className="overlay-body-popover m-0 p-0">
                        <Container className="w-100 p-0 m-0">
                          <Stack direction="vertical" gap={2} className="bg-white m-0 p-2 overlay-body-popover">
                            <div>
                              <span>Chart Type</span>
                              <Stack gap={2} direction="horizontal"><img onClick={() => handleLineChartTypeChange()} src={lineChart} width={"20px"} height={"20px"} /><img onClick={() => handleBarChartTypeChange()} src={barChart} width={"20px"} height={"20px"} /></Stack>
                            </div>
                            <div><span>X-Axis Data</span>
                              <Dropdown align="end" drop="end" className="ana-drop-down">
                                <Dropdown.Toggle size="sm" as={({ children, onClick }) => (
                                  <div
                                    className="d-flex justify-content-between"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      onClick(e);
                                    }}
                                  >
                                    {children}
                                    <KeyboardArrowDownOutlinedIcon />
                                  </div>
                                )} id="dropdown-custom-components">
                                  TimeLine
                                </Dropdown.Toggle>

                              </Dropdown>
                            </div>
                            <div>
                              <span>Y-Axis Data</span>
                              <Dropdown drop="down" align="start" className="ana-drop-down">
                                <Dropdown.Toggle size="sm" as={({ children, onClick }) => (
                                  <div
                                    className="d-flex justify-content-between"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      onClick(e);
                                    }}
                                  >
                                    {children}
                                    <KeyboardArrowDownOutlinedIcon />
                                  </div>
                                )} id="dropdown-custom-components">
                                  Production
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="ana-drop-menu">
                                  <div className="d-flex  flex-column align-items-left justify-content-center">
                                    {selectColumns.map(
                                      (column) =>
                                        <Form.Check size="sm" className="drop-menu" label={column}
                                          type="checkbox"
                                          name={column}
                                          checked={checkedOptions.includes(column)}
                                          onChange={handleCheckboxChange}
                                          disabled={!checkedOptions.includes(column) && checkedOptions.length >= 2}
                                        />
                                    )}
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </Stack>
                        </Container>
                      </Popover.Body>
                    </Popover>
                  }>
                    <img
                      src={SettingIcon}
                      alt="filter"
                      className="filter-icon"
                    />
                  </OverlayTrigger>
                </div> : <></>}
              </div>
            </Card.Header>
            <Card.Body>
              <div className="hp-chart-container">
                <ResponsiveContainer height={400}>
                  {renderData()}
                </ResponsiveContainer>
              </div>

              {/* Modal structure */}
              {isModalOpen && (
                <div className={`hp-modal open`}>
                  <div className="hp-modal-content">
                    <button
                      className="hp-modal-close"
                      onClick={() => setIsModalOpen(false)}>
                      &times;
                    </button>
                    <div className="hp-modal-header">
                      <h2 className="hp-modal-title">Historical Production</h2>
                    </div>
                    <div className="hp-modal-card-content">
                      <ResponsiveContainer height={500}>
                        {renderData()}
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </Row>
      </Container >
    </div >
  );
};

export default IndividualAnalytic;
