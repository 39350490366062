import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { FaEye, FaEyeSlash, FaTimes } from "react-icons/fa";
import "./Signup.css";
import backgroundImage from "../assets/solar-dl-login-bg-2x.png"; // Adjust the path according to your project structure
import logo from "../assets/logog.svg";
import { MessageContext } from "../App";
// import details from "../assets/detailsbutton.svg";

const signupCard = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false); // Password visibility toggle
  const [mobileNumber, setMobileNumber] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [country, setCountry] = useState(""); // Initialize as an empty string to store country_name

  const [entityName, setEntityName] = useState("");
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [timer, setTimer] = useState(20);
  const [showResend, setShowResend] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false); // Tracks email verification status
  const [isEmailLocked, setIsEmailLocked] = useState(false); // State to lock email field
  const otpRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [countries, setCountries] = useState([]); // State to store countries
  const [isLoadingCountries, setIsLoadingCountries] = useState(false); // Loading state
  const [countryError, setCountryError] = useState(null); // Error state

  const [isEmailWarningShown, setIsEmailWarningShown] = useState(false);

  // Regex for email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const onMessageChange = useContext(MessageContext);

  // Fetch countries from API using POST method
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(
          "https://solar-api.antsai.in/api/countries",
          {
            method: "POST", // Ensure this is the correct method
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({}), // Adjust payload if required by API
          }
        );

        if (response.ok) {
          const data = await response.json();
          console.log("Fetched countries:", data); // Debugging

          // Check if data is an array
          if (Array.isArray(data)) {
            setCountries(data);
          } else {
            console.error("Unexpected data format:", data);
          }
        } else {
          console.error(
            `Failed to fetch countries: ${response.status} ${response.statusText}`
          );
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  // Function to validate entity name for length and allowed characters
  const validateEntityName = () => /^[a-zA-Z0-9&]{1,30}$/.test(entityName);

  // Function to validate pin code for 6-digit numeric input
  const validatePinCode = () => /^\d{6}$/.test(pinCode);

  // Function to validate name fields for letters only and max length
  const validateName = (name) => /^[a-zA-Z]{1,30}$/.test(name);

  const handleSignUp = async () => {
    // Check if email is verified
    if (!isEmailVerified) {
      onMessageChange({
        type: "Danger",
        info: "Please verify your email before signing up.",
      });
      return;
    }

    // Check if country is selected
    if (!country) {
      onMessageChange({
        type: "Danger",
        info: "Please select a country.",
      });
      return;
    }

    // Check for missing required fields
    if (
      !firstName ||
      !lastName ||
      !email ||
      !password ||
      !mobileNumber ||
      !pinCode ||
      !entityName
    ) {
      onMessageChange({
        type: "Danger",
        info: "Please fill in all required fields.",
      });
      return;
    }

    // Check password format
    if (
      !/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\-=/\\]).{8,15}$/.test(
        password
      )
    ) {
      onMessageChange({
        type: "Danger",
        info: "Password must be 8-15 characters, contain at least one uppercase letter, one number, and one special character.",
      });
      return;
    }

    // Check if mobile number is valid
    if (!/^\d{10,15}$/.test(mobileNumber)) {
      onMessageChange({
        type: "Danger",
        info: "Mobile number should be between 10 to 15 digits.",
      });
      return;
    }

    // Check if pin code is valid
    if (!/^\d{5,6}$/.test(pinCode)) {
      onMessageChange({
        type: "Danger",
        info: "Pin code should be between 5 and 6 digits.",
      });
      return;
    }

    // Set up the request payload
    const payload = {
      firstName,
      lastName,
      email,
      password,
      mobileNumber: `+91 ${mobileNumber}`,
      pinCode,
      country,
      entityName,
      otp_status: true, // assuming OTP is already verified
    };

    console.log("Signup payload:", payload);

    try {
      const response = await fetch("https://solar-api.antsai.in/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        // Signup successful
        onMessageChange({
          type: "Success",
          info: "Signup successful! Redirecting to login...",
        });
        navigate("/login");
      } else {
        const errorData = await response.json();
        // Show server-provided error message or generic error
        onMessageChange({
          type: "Danger",
          info: `Signup failed: ${
            errorData.message || "An error occurred. Please try again."
          }`,
        });
      }
    } catch (error) {
      console.error("Error during signup:", error);
      // Show generic error if fetch fails
      onMessageChange({
        type: "Danger",
        info: "An error occurred. Please check your connection and try again.",
      });
    }
  };

  const handleLogin = () => {
    // Perform login validation here (e.g., check if fields are filled)

    // Redirect to the / route
    navigate("/");
  };

  // Handle password visibility toggle
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // // Send OTP
  // const handleVerifyClick = async () => {
  //   if (!email) {
  //     // alert("Please enter your email first.");

  //     onMessageChange({
  //       type: "Danger",
  //       info: "Please enter your email first.",
  //     });
  //     return;
  //   }

  //   try {
  //     const response = await fetch("https://solar-api.antsai.in/send-otp", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ user_email: email }),
  //     });

  //     if (response.ok) {
  //       setIsOtpModalOpen(true);
  //       setTimer(20);
  //       setShowResend(false);
  //       setOtp(["", "", "", ""]);
  //       // alert('OTP sent to your email!');
  //       onMessageChange({ type: "Success", info: "OTP sent to your email!" });
  //     } else {
  //       // alert('Failed to send OTP. Please try again.');
  //       onMessageChange({
  //         type: "Danger",
  //         info: "Failed to send OTP. Please try again.",
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error sending OTP:", error);
  //   }
  // };

  const handleVerifyClick = async () => {
    if (!email) {
      onMessageChange({
        type: "Warning",
        info: "Please enter your email first.",
      });
      return;
    }

    try {
      // Step 1: Check if the email already exists
      const checkEmailResponse = await fetch(
        "https://solar-api.antsai.in/checkemail",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: email }),
        }
      );

      const checkEmailData = await checkEmailResponse.json();

      if (checkEmailData.exists) {
        // If email exists, show message and stop further actions
        onMessageChange({
          type: "Danger",
          info: "User already exists.",
        });
        return;
      }

      // Step 2: If email does not exist, send OTP
      const otpResponse = await fetch("https://solar-api.antsai.in/send-otp", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_email: email }),
      });

      if (otpResponse.ok) {
        setIsOtpModalOpen(true);
        setTimer(20);
        setShowResend(false);
        setOtp(["", "", "", ""]);
        onMessageChange({
          type: "Success",
          info: "OTP sent to your email!",
        });
      } else {
        onMessageChange({
          type: "Danger",
          info: "Failed to send OTP. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error in checking email or sending OTP:", error);
      onMessageChange({
        type: "Danger",
        info: "An error occurred. Please try again.",
      });
    }
  };

  // Resend OTP
  const handleResendOtp = async () => {
    setTimer(20);
    setShowResend(false);
    setOtp(["", "", "", ""]);
    otpRefs[0].current.focus();
    try {
      const response = await fetch("https://solar-api.antsai.in/send-otp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_email: email }),
      });

      if (response.ok) {
        // alert('OTP resent successfully!');
        onMessageChange({ type: "Success", info: "OTP resent successfully!" });
      } else {
        // alert('Failed to resend OTP. Please try again.');
        onMessageChange({
          type: "Danger",
          info: "Failed to resend OTP. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  // Handles OTP input change
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value !== "" && index < 3) {
        otpRefs[index + 1].current.focus();
      }
    }
  };

  // Verify OTP
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const otpValue = otp.join("");

    if (otpValue.length === 4) {
      try {
        const response = await fetch("https://solar-api.antsai.in/verify-otp", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_email: email, otp: otpValue }),
        });

        if (response.ok) {
          setIsOtpValid(true);
          setIsEmailVerified(true); // Email verified successfully
          // alert('OTP Verified!');
          setIsEmailLocked(true); // Lock email after successful OTP verification
          onMessageChange({ type: "Success", info: "OTP Verified!" });
          setIsOtpModalOpen(false);
        } else {
          setIsOtpValid(false);
          // alert('Invalid OTP. Please try again.');
          onMessageChange({
            type: "Danger",
            info: "Invalid OTP. Please try again.",
          });
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
      }
    } else {
      setIsOtpValid(false);
      // alert('Please enter a valid 4-digit OTP.');
      onMessageChange({
        type: "Danger",
        info: "Please enter a valid 4-digit OTP.",
      });
    }
  };

  // Countdown timer effect
  useEffect(() => {
    if (isOtpModalOpen && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(countdown);
    } else if (timer === 0) {
      setShowResend(true);
    }
  }, [timer, isOtpModalOpen]);

  // Close OTP modal
  const handleCloseOtpModal = () => {
    setIsOtpModalOpen(false);
  };

  const removeToast = (id) => {};

  return (
    <div
      className="signup-card-container"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="signup-card">
        <div className="signup-container">
          <div className="signup-logo-container">
            <img src={logo} className="signup-logo" />
          </div>
          <div className="signup-form-container">
            <div className="signup-title-container">
              <h2 className="signup-title">
                <span>Sign Up</span>
              </h2>
            </div>
            <div className="signup-inputs-container">
              <div className="signup-input-left">
                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="user-id">
                    First Name <span className="signup-required">*</span>
                  </label>
                  <input
                    type="text"
                    id="first-name"
                    placeholder="First Name"
                    className="signup-input"
                    value={firstName}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only alphabetic characters and limit to 30 characters
                      if (/^[a-zA-Z]{0,30}$/.test(value)) {
                        setFirstName(value);
                      }
                    }}
                  />
                </div>
                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="password">
                    Password <span className="signup-required">*</span>
                  </label>
                  <div style={{ position: "relative" }}>
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      value={password}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Allow user to input freely
                        setPassword(value);
                      }}
                      onBlur={() => {
                        // Validate password format onBlur and provide feedback if invalid
                        if (
                          !/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\-=/\\]).{8,15}$/.test(
                            password
                          )
                        ) {
                          onMessageChange({
                            type: "Danger",
                            info: "Password must be 8-15 characters, contain at least one uppercase letter, one number, and one special character.",
                          });
                        }
                      }}
                      placeholder="Enter password"
                      className="signup-input"
                    />

                    <span
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "42%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        color: "#888",
                      }}
                    >
                      {isPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  </div>
                </div>
                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="password">
                    Mobile Number <span className="required">*</span>
                  </label>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <select style={{ width: "30%" }} className="signup-input">
                      <option value="+91">+91</option>
                      <option value="+1">+1</option>
                      <option value="+2">+2</option>
                      <option value="+3">+3</option>
                    </select>
                    <input
                      type="text"
                      id="mobile-number"
                      placeholder="Mobile Number"
                      className="signup-input"
                      value={mobileNumber}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Allow only numeric values and limit to 15 digits
                        if (/^\d{0,15}$/.test(value)) {
                          setMobileNumber(value);
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="user-id">
                    Pincode <span className="signup-required">*</span>
                  </label>
                  {/* <input
                    type="text"
                    id="signup-pincode"
                    placeholder=" Pincode"
                    className="signup-input"
                    value={pinCode}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only numeric values and limit to 6 characters
                      if (/^\d{0,6}$/.test(value)) {
                        setPinCode(value);
                      }
                    }}
                  /> */}
                  <input
                    type="text"
                    id="signup-pincode"
                    placeholder="Pincode"
                    className="signup-input"
                    value={pinCode}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only numeric values and limit to 6 characters
                      if (/^\d{0,6}$/.test(value)) {
                        setPinCode(value);
                      }
                    }}
                    onBlur={() => {
                      // Display message if pin code length is not exactly 6 digits
                      if (pinCode.length < 6) {
                        onMessageChange({
                          type: "Warning",
                          info: "Pin code should be exactly 6 digits.",
                        });
                      } else if (pinCode.length > 6) {
                        onMessageChange({
                          type: "Warning",
                          info: "Pin code should not exceed 6 digits.",
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="signup-input-right">
                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="user-id">
                    Last Name <span className="signup-required">*</span>
                  </label>
                  <input
                    type="text"
                    id="last-name"
                    placeholder="Last Name"
                    className="signup-input"
                    value={lastName}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only alphabetic characters and limit to 30 characters
                      if (/^[a-zA-Z]{0,30}$/.test(value)) {
                        setLastName(value);
                      }
                    }}
                  />
                </div>
                <div className="signup-input-container">
                  <label className="signup-input-label">
                    Email ID <span className="signup-required">*</span>
                  </label>
                  <div style={{ display: "flex", gap: "10px" }}>
                    {/* <input
                      type="email"
                      id="email"
                      placeholder="Email ID"
                      className="signup-input"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={isEmailLocked} // Lock email field if verified
                    /> */}
                    <input
                      type="email"
                      id="email"
                      placeholder="Email ID"
                      className="signup-input"
                      value={email}
                      disabled={isEmailLocked} // Lock email field if verified
                      onChange={(e) => {
                        const value = e.target.value;
                        setEmail(value);

                        // Check the email format as user types and provide feedback if invalid
                        if (!emailRegex.test(value) && !isEmailWarningShown) {
                          onMessageChange({
                            type: "Warning",
                            info: "Please enter a valid email address.",
                          });
                          setIsEmailWarningShown(true); // Set the warning as shown
                        } else if (emailRegex.test(value)) {
                          setIsEmailWarningShown(false); // Reset the warning if the email becomes valid
                        }
                      }}
                      onBlur={() => {
                        // Final check when user leaves the field
                        if (!emailRegex.test(email) && !isEmailWarningShown) {
                          onMessageChange({
                            type: "Warning",
                            info: "Please enter a valid email address.",
                          });
                          setIsEmailWarningShown(true); // Set the warning as shown
                        }
                      }}
                    />
                    {/* <button
                      className="signup-verify-button"
                      onClick={handleVerifyClick}
                      disabled={isEmailLocked} // Disable verify button if verified
                    > */}
                    <button
                      className="signup-verify-button"
                      disabled={isEmailLocked} // Lock email field if verified
                      onClick={(e) => {
                        e.preventDefault();
                        // Verify button functionality
                        if (emailRegex.test(email)) {
                          handleVerifyClick();
                        } else {
                          onMessageChange({
                            type: "Warning",
                            info: "Please enter a valid email before verifying.",
                          });
                        }
                      }}
                    >
                      Verify
                    </button>
                  </div>
                </div>

                {/* OTP Modal */}
                {isOtpModalOpen && (
                  <>
                    <div
                      className="otp-backdrop"
                      onClick={handleCloseOtpModal}
                    ></div>{" "}
                    {/* Backdrop */}
                    <div className="otp-modal">
                      <div className="otp-modal-content">
                        <span
                          className="otp-close-icon"
                          onClick={handleCloseOtpModal}
                        >
                          <FaTimes />
                        </span>
                        <h3>Enter OTP</h3>
                        <div className="otp-inputs">
                          {otp.map((digit, index) => (
                            <input
                              key={index}
                              type="text"
                              maxLength="1"
                              value={digit}
                              onChange={(e) => handleOtpChange(e, index)}
                              ref={otpRefs[index]}
                              className="otp-input"
                            />
                          ))}
                        </div>
                        {timer > 0 ? (
                          <span className="otp-timer">Time left: {timer}s</span>
                        ) : (
                          <button
                            onClick={handleResendOtp}
                            className="resend-otp-button"
                          >
                            Resend OTP
                          </button>
                        )}
                        {!isOtpValid && (
                          <p className="error-message">
                            Please enter a valid 4-digit OTP.
                          </p>
                        )}
                        <div className="otp-modal-buttons">
                          <button
                            onClick={handleOtpSubmit}
                            className="submit-otp-button"
                          >
                            Submit OTP
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="country">
                    Country <span className="signup-required">*</span>
                  </label>
                  <select
                    className="signup-input"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)} // Store country_name directly
                  >
                    <option value="">Select Country</option>
                    {countries.map((countryItem) => (
                      <option
                        key={countryItem.country_id}
                        value={countryItem.country_name}
                      >
                        {countryItem.country_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="signup-input-container">
                  <label className="signup-input-label" htmlFor="user-id">
                    Entity Name <span className="signup-required">*</span>
                  </label>
                  <input
                    type="text"
                    id="entity-name"
                    placeholder="Entity Name"
                    className="signup-input"
                    value={entityName}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow alphanumeric characters, spaces, the '&' symbol, and limit to 30 characters
                      if (/^[a-zA-Z0-9& ]{0,30}$/.test(value)) {
                        setEntityName(value);
                      }
                    }}
                  />
                </div>

                <button className="signup-button" onClick={handleSignUp}>
                  Sign Up
                </button>
              </div>
            </div>
            <div className="signup-button-container">
              <span className="signup-login-already">Already Have Account</span>
              <a href="" className="signup-login-back" onClick={handleLogin}>
                Login
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='d-flex flex-row'> <NotificationManager className="align-items-end" message={notifications} /></div> */}
    </div>
  );
};

export default signupCard;
