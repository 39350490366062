import { FormControl, MenuItem, Select } from "@mui/material"

function DateFilterOption({options,onValueSelect}){
return(
  <Select style={{ minWidth: 100, marginRight: 16, }}  onChange={onValueSelect} 
  sx={{maxHeight: 27}}>
    {options.map((v)=>{return <MenuItem value={v}>{v}</MenuItem> })}
  </Select>
)

}
export default DateFilterOption