import React, { useState } from 'react';
import { Container, Row, Accordion, Card, Button, Stack, Col, Form } from "react-bootstrap"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function Roles() {
    function Styles() {
        return <style>{`
            .role-title{
            font-size:28px;
            color:#343544;
            font-weight:400;
            padding-left: 0px;
            }

            .toggle-icon{
                
    font-size: 15px;
    font-weight: bold;
    border: 5px black;
        box-shadow: 0px 0px 0px 1px grey;
    border-radius: 2px;
            }

            .module-row{
            height: 100px;
    background-color: #f2f5f7;
    display: flex;
    align-items: center;
            }

    .admin-row{
      height: 50px;
    background-color: white;
    display: flex;
    align-items: center;
    }

    .dashboard-row{    
    background-color: #f2f5f7;
     height: 50px;
    display: flex;
    align-items: center;
    }

    .dashboard-body{    
    background-color: #f2f5f7;
        }

    .plantoverview-row{
     background-color: white;
      height: 50px;
    display: flex;
    align-items: center;
    }

     .plant-row{
      background-color: #f2f5f7;
       height: 50px;
    display: flex;
    align-items: center;
      }

      .plant-body{    
    background-color: #f2f5f7;
        }

      .maintenance-row{
      background-color:white;
       height: 50px;
    display: flex;
    align-items: center;

      }

      .analytic-row{
       background-color: #f2f5f7;
        height: 50px;
    display: flex;
    align-items: center;
      }

      .analytic-body{    
    background-color: #f2f5f7;
        }

       .report-row{
       background-color:white;
        height: 50px;
    display: flex;
    align-items: center;
       }

       .role-item{
       padding-bottom:0px;
       border-bottom:0px;
       border-top:0px;
       border-left:2px;
       border-right:2px;
       }  
      
       .role-item  .row{
        display: flex;
    align-items: center;
    
       }
       
    //    .module-row .col:nth-child(even){
    //       border-width: 0px 0.5px 0px 0.5px;
    // border-style: solid;
    // border-color: grey;
    // height: inherit;
    //    display: flex;
    //     align-items: center;
    //    }

       .module-row .col{
           border-width: 0px 0px 0px 0px;
    box-shadow: -1px 0px 0px lightgray;
    border-style: solid;
    border-color: grey;
    height: inherit;
    display: flex;
    align-items: center;
       }

       .module-col{
        display: flex;
        align-items: center;
    justify-content: center;
       }

    //     .role-item  .col:nth-child(even){
    //   border-width: 0px 0.5px 0px 0.5px;
    // border-style: solid;
    // border-color: grey;
    // height: inherit;
    //     display: flex;
    // align-items: center;
    //    }

       .role-item .col{
           border-width: 0px 0px 0px 0px;
    box-shadow: -1px 0px 0px lightgray;
    border-style: solid;
    border-color: grey;
    height: inherit;
    display: flex;
    align-items: center;
       }

    // .role-item .row .col:not(:first-child) div{
    // padding-left:5px;
    // }
    
    // .role-item .row .col div{
    // padding-left:5px;
    // }

       .accordion-role{      
    padding: 0px;
         border: 0px solid grey;
    box-shadow: 0px 0px 1px 1px lightgrey;
    border-radius: 2px;
    font-size: 18px;
    font-weight: 400;
    color: #0F0D0D;
       }

    .text-extra{
    font-size: 18px;
    font-weight: 500;
    color: #0F0D0D;
    }
       .body-extra{
       padding-bottom:20px;
       }
       
       .module-item{
    width: 95%;
    height: 70px;
    box-shadow: 1px 2px 3px #EFEFEF90;
    border: 1px solid grey;
    border-radius: 9px;
    opacity: 1;
    background: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
       }

    .role-button{
   
    padding: 2px 10px 2px 10px;
    background-color: #167DB0;
    color: #fff;
    border: none;
    border-radius:5px;
    box-shadow: 1px 2px 3px #3435442F;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    }

    .role-input{
    padding:2px 5px 2px 5px;
     border: 1px solid #C2CAD2;
    border-radius:9px;
    box-shadow: 1px 2px 3px #EFEFEF90;
    font-size: 20px;
    }

    .module-select{
    border-color:#167DB0;
    border-width:5px;
    

    }

          
            `}</style>
    }

    const [activeKey, setActiveKey] = useState([]);
    const adminModule = ['Entity', 'User', 'Role', 'Report Templates', 'Catalogue', 'Settings']
    const dashboardModule = ['Global filter', 'All Widgets', 'widget 1', 'widget 2', 'widget 3', 'widget 4']
    const plantOverviewModule = ['Entity', 'User', 'Role', 'Report Templates', 'Catalogue', 'Settings']
    const plantModule = ['Role', 'Report Templates', 'Catalogue', 'Settings']
    const maintenanceModule = ['Catalogue', 'Settings']
    const analyticModule = ['Entity', 'User', 'Role', 'Report Templates', 'Catalogue', 'Settings']
    const reportModule = ['Catalogue', 'Settings']
    const [module,setModule] =useState([{ title: 'System Administrator', status: false }, { title: 'Plant Manager', status: false }, { title: 'Field Engineer', status: false }, { title: 'Individual', status: false },]);

    const toggleIcon = (key) => (activeKey.includes(key) ? <RemoveIcon className='toggle-icon' style={{ color: 'black', background: 'white' }} /> : <AddIcon className='toggle-icon' style={{ color: 'black', background: 'lightgrey' }} />);
    const toggleAllIcon = () => (activeKey.length <= 0 ? <AddIcon className='toggle-icon' style={{ color: 'black', background: 'lightgrey' }} /> : <RemoveIcon className='toggle-icon' style={{ color: 'black', background: 'white' }} />);
    // const handleToggle = (key) => {
    //     if (activeKey === key) {
    //         setActiveKey(null); // Close if already open
    //     } else {
    //         setActiveKey(key); // Open selected
    //     }
    // };

    // Function to toggle all accordion items at once
    const toggleAll = () => {
        if (activeKey.length === 7) {
            setActiveKey([]); // Close all
        } else {
            const v = [1, 2, 3, 4, 5, 6, 7]
            setActiveKey([...v]); // Open all
        }
    };

    // Function to toggle individual items
    const toggleItem = (key) => {
        if (activeKey.includes(key)) {
            setActiveKey(activeKey.filter(item => item !== key)); // Close the item
        } else {
            setActiveKey([key])
        }
        // else {
        //   setActiveKey([...activeKey, key]); // Open the item
        // }
    };

    const handleModuleSelection=(m)=>{
        module.forEach((md)=>(md.title === m.title ? md.status = true : md.status = false));
       setModule([...module])
    }
    

    return (
        <Container as={"div"} fluid className="mw-100">
            <Styles />
            <Row className="mt-2 ml-1 pt-2">

                <div className="d-flex justify-content-left align-items-center role-title"><span>Role Based Access</span></div>
            </Row>
            <Row>
                <Accordion activeKey={activeKey} className='accordion-role'>
                    <Row className='module-row px-0 mx-0'>
                        <Col>
                            <div className='d-flex flex-row align-items-center gap-2' onClick={() => toggleAll()} >
                                {toggleAllIcon()} <div className='text-extra'> Module</div></div>

                        </Col>
                        {module.map((m) => (
                            <Col className='module-col'>
                                <div key={m.title} className={`module-item text-extra ${m.status===true ?'module-select':''}`} onClick={()=>handleModuleSelection(m)}>{m.title}</div>
                            </Col>
                        ))}
                        {/* <Col className='module-col'>
                            <div className='module-item text-extra'>System Administrator</div>
                        </Col>
                        <Col className='module-col'>
                            <div className='module-item text-extra'>Plant Manager</div>
                        </Col>
                        <Col className='module-col'>
                            <div className='module-item text-extra'>Field Engineer</div>
                        </Col>
                        <Col className='module-col'>
                            <div className='module-item text-extra'>Individual</div>
                        </Col> */}
                    </Row>
                    <Accordion.Item eventKey={1} className='role-item'>
                        {/* <Accordion.Header > */}
                        <Row className='admin-row px-0 mx-0'>
                            <Col>
                                <div className='d-flex flex-row align-items-center gap-2' onClick={() => toggleItem(1)} >
                                    {toggleIcon(1)} <div className='text-extra'> Admin</div></div>
                            </Col>
                            <Col>
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                            <Col >
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                            <Col >
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                            <Col >
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                        </Row>
                        {/* </Accordion.Header> */}
                        <Accordion.Body className='py-0 px-0 admin-body'>
                            <Row className='px-0 mx-0'>
                                <Col>
                                    <div></div>
                                </Col>
                                <Col>
                                    <div>
                                        <Stack direction='vertical' gap={2}>
                                            {adminModule.map((a) => {
                                                return <div className='d-flex flex-row gap-2'> <Form>
                                                    <Form.Check // prettier-ignore
                                                        type='checkbox'
                                                        className="table-column-checkbox"
                                                        placeholder='All Modules'
                                                    />
                                                </Form>{a}</div>
                                            })}
                                            <div className='body-extra'></div>
                                            <div className='body-extra'></div></Stack>
                                    </div>
                                </Col>
                                <Col>
                                    <div>
                                        <Stack direction='vertical' gap={2}>
                                            {adminModule.map((a) => {
                                                return <div className='d-flex flex-row gap-2'> <Form>
                                                    <Form.Check // prettier-ignore
                                                        type='checkbox'
                                                        className="table-column-checkbox"
                                                        placeholder='All Modules'
                                                    />
                                                </Form>{a}</div>
                                            })}
                                            <div className='body-extra'></div>
                                            <div className='body-extra'></div></Stack>
                                    </div>
                                </Col>
                                <Col>
                                    <div>
                                        <Stack direction='vertical' gap={2}>
                                            {adminModule.map((a) => {
                                                return <div className='d-flex flex-row gap-2'> <Form>
                                                    <Form.Check // prettier-ignore
                                                        type='checkbox'
                                                        className="table-column-checkbox"
                                                        placeholder='All Modules'
                                                    />
                                                </Form>{a}</div>
                                            })}
                                            <div className='body-extra'></div>
                                            <div className='body-extra'></div></Stack>
                                    </div>
                                </Col>
                                <Col>
                                    <div>
                                        <Stack direction='vertical' gap={2}>
                                            {adminModule.map((a) => {
                                                return <div className='d-flex flex-row gap-2'> <Form>
                                                    <Form.Check // prettier-ignore
                                                        type='checkbox'
                                                        className="table-column-checkbox"
                                                        placeholder='All Modules'
                                                    />
                                                </Form>{a}</div>
                                            })}
                                            <div className='body-extra'></div>
                                            <div className='body-extra'></div></Stack>
                                    </div>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={2} className='role-item'>
                        {/* <Accordion.Header> */}

                        <Row className='dashboard-row px-0 mx-0'>
                            <Col>
                                <div className='d-flex flex-row align-items-center gap-2' onClick={() => toggleItem(2)} >
                                    {toggleIcon(2)} <div className='text-extra'> Dashboard</div></div>
                            </Col>
                            <Col>
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                            <Col >
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                            <Col >
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                            <Col >
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                        </Row>

                        {/* </Accordion.Header> */}
                        <Accordion.Body className='py-0 px-0 dashboard-body' >
                            <Row className='px-0 mx-0'>
                                <Col>
                                    <div></div>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {dashboardModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}

                                        <div className='body-extra'></div></Stack>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {dashboardModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {dashboardModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {dashboardModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={3} className='role-item'>
                        {/* <Accordion.Header> */}
                        <Row className='plantoverview-row px-0 mx-0'>
                            <Col>
                                <div className='d-flex flex-row align-items-center gap-2' onClick={() => toggleItem(3)} >
                                    {toggleIcon(3)} <div className='text-extra'> Plant Overview</div></div>
                            </Col>
                            <Col>
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                            <Col >
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                            <Col >
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                            <Col >
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                        </Row>
                        {/* </Accordion.Header> */}
                        <Accordion.Body className='py-0 px-0 plantoverview-body' >
                            <Row className='px-0 mx-0'>
                                <Col>
                                    <div></div>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {plantOverviewModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {plantOverviewModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {plantOverviewModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {plantOverviewModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={4} className='role-item'>
                        {/* <Accordion.Header> */}
                        <Row className='plant-row px-0 mx-0'>
                            <Col>
                                <div className='d-flex flex-row align-items-center gap-2' onClick={() => toggleItem(4)} >
                                    {toggleIcon(4)} <div className='text-extra'> Plant</div></div>
                            </Col>
                            <Col>
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                            <Col >
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                            <Col >
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                            <Col >
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                        </Row>
                        {/* </Accordion.Header> */}
                        <Accordion.Body className='py-0 px-0 plant-body' >
                            <Row className='px-0 mx-0'>
                                <Col>
                                    <div></div>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {plantModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {plantModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {plantModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {plantModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={5} className='role-item'>
                        {/* <Accordion.Header> */}
                        <Row className='maintenance-row px-0 mx-0'>
                            <Col>
                                <div className='d-flex flex-row align-items-center gap-2' onClick={() => toggleItem(5)} >
                                    {toggleIcon(5)} <div className='text-extra'> Maintenance</div></div>
                            </Col>
                            <Col>
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                            <Col >
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                            <Col >
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                            <Col >
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                        </Row>
                        {/* </Accordion.Header> */}
                        <Accordion.Body className='py-0 px-0 maintenance-body' >
                            <Row className='px-0 mx-0'>
                                <Col>
                                    <div></div>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {maintenanceModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {maintenanceModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {maintenanceModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {maintenanceModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={6} className='role-item'>
                        {/* <Accordion.Header> */}
                        <Row className='analytic-row px-0 mx-0'>
                            <Col>
                                <div className='d-flex flex-row align-items-center gap-2' onClick={() => toggleItem(6)} >
                                    {toggleIcon(6)} <div className='text-extra'> Analytics</div></div>
                            </Col>
                            <Col>
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                            <Col >
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                            <Col >
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                            <Col >
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                        </Row>
                        {/* </Accordion.Header> */}
                        <Accordion.Body className='py-0 px-0 analytic-body' >
                            <Row className='px-0 mx-0'>
                                <Col>
                                    <div></div>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {analyticModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {analyticModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {analyticModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {analyticModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={7} className='role-item'>
                        {/* <Accordion.Header> */}
                        <Row className='report-row px-0 mx-0'>
                            <Col>
                                <div className='d-flex flex-row align-items-center gap-2' onClick={() => toggleItem(7)} >
                                    {toggleIcon(7)} <div className='text-extra'> Reports</div></div>
                            </Col>
                            <Col>
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                            <Col >
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                            <Col >
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                            <Col >
                                <div className='d-flex flex-row gap-2'> <Form>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        className="table-column-checkbox"
                                        placeholder='All Modules'
                                    />
                                </Form>All Modules</div>
                            </Col>
                        </Row>
                        {/* </Accordion.Header> */}
                        <Accordion.Body className='py-0 px-0 report-body' >
                            <Row className='px-0 mx-0'>
                                <Col>
                                    <div></div>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {reportModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {reportModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {reportModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                                <Col>
                                    <Stack direction='vertical' gap={2}>
                                        {reportModule.map((a) => {
                                            return <div className='d-flex flex-row gap-2'> <Form>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    className="table-column-checkbox"
                                                    placeholder='All Modules'
                                                />
                                            </Form>{a}</div>
                                        })}
                                        <div className='body-extra'></div></Stack>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

            </Row>
            <Row className="my-3">
                <Col className='p-0 m-0'>
                    <div className="d-flex justify-content-left align-items-center gap-2">
                        <input className='role-input' type='text' placeholder='Role Name' />
                        <button className="role-button" >
                            Save as New Role
                        </button>
                    </div>
                </Col><Col className='p-0 m-0'>
                    <div className="d-flex justify-content-end align-items-center gap-2">
                        <button className="role-button px-4" >
                            Reset
                        </button> <button className="role-button px-4" >
                            Save
                        </button>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export default Roles