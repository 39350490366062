import React, { useState, useMemo } from "react";
import "./PlantTable.css"; // Importing the styles
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const PlantBulkImportTable = ({stateChanger}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({
    plantSerialNumber:true,
    plantName: true, // Always visible and uncheckable
    capacity: true,
    dataLoggers: true,
    invertors: true,
    status: true,
   
  });
  const [selectedFilter, setSelectedFilter] = useState(""); // No filter selected by default
  const [searchQuery, setSearchQuery] = useState(""); // Search input
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [selectedRows, setSelectedRows] = useState([])
  const [update, setUpdate] = useState(false);
  const [tableProps,setTableProps]=useState({rowsPerPage:5,currentPage:1,pageFirst:1,pageLast:5})

  const navigate = useNavigate();

  const plantDetailsPage = (e, row) => {
    e.preventDefault()
  
    navigate("/admin/plant/edit", { state: { "plant": row } });
  }

  const downloadLink = () => {
    alert("Download action");
  }


  const handleDropdownToggle = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleFilterToggle = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const handleRowsPerPage = (value) => {

    setRowsPerPage(value)
  }

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
    setIsFilterVisible(false); // Close the filter dropdown after selecting
  };

  const handleColumnSelect = (column) => {
    setSelectedColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  const handleSort = (column) => {
    let direction = "ascending";
    if (sortConfig.key === column && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: column, direction });
  };

  const { t } = useTranslation(['plant']);

  const plantNames = ["Gayitri"];

  const plantTypes = [
    "Residential"
  ];
  
  const capacities = [
    "2KWH"
  ];
  const dataLoggers = [
    "Data Loggers"
  ];
  const invertors = ["Invertors"];

  const alerts = [
    "Normal",
    "Major",
    "Normal",
    "Critical",
  ];

  const status = [
    "Online",
    "Offline",
    "Partially Offline"
  ];

  // Generate 20 random plant entries
  const [data, setData] = useState(() => {
    return Array.from({ length: 20 }, (_, index) => ({
      plantSerialNumber: "01103"+index + 1,
      plantName: plantNames[Math.floor(Math.random() * plantNames.length)],
      capacity: capacities[Math.floor(Math.random() * capacities.length)],
      dataLoggers: dataLoggers[Math.floor(Math.random() * dataLoggers.length)],
      invertors: invertors[Math.floor(Math.random() * invertors.length)],      
      status: status[Math.floor(Math.random() * status.length)],

    }))
  });

  // Sorting logic based on the updown icon click
  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig, searchQuery]);

  // Filtering logic (only filters if a filter is selected and a search query is entered)
  const filteredData = useMemo(() => {
    return sortedData.filter((row) => {
      if (!selectedFilter || !searchQuery) return true; // No filter if no filter or search query is provided
      return row[selectedFilter]
        .toLowerCase()
        .startsWith(searchQuery.toLowerCase()); // Filter matches only if it starts with the query
    })
  }, [update, sortConfig, searchQuery]);

  // Pagination logic
  const currentRows = useMemo(() => {    
    if (filteredData.length > 0) {
      return filteredData.slice(((tableProps.currentPage - 1) * tableProps.rowsPerPage), (((tableProps.currentPage - 1) * tableProps.rowsPerPage) + tableProps.rowsPerPage))
    }
    return []
  }, [filteredData, selectedRows, tableProps]);
  
  const tablePropsChange = (tableProps) =>{
    setTableProps(tableProps);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handling a maximum of 7 columns and hiding the rest in horizontal scrolling
  const visibleColumns = Object.keys(selectedColumns).filter(
    (key) => selectedColumns[key]
  );

  //handling filter and dropdown select toggle outside
  function handleAllToggleEvents() {
    isFilterVisible == true ? setIsFilterVisible(false) : "";
    isDropdownVisible == true ? setIsDropdownVisible(false) : ""
  }

  //disable row
  const disableRow = (e, rowData) => {
    e.stopPropagation();
    const index = filteredData.findIndex(dataItem => dataItem.id === rowData.id);
    rowData.status = "offline";
    filteredData.splice(index, 1, rowData)
    setUpdate(!update)
  }

  const selectRows = (e, rowData) => {
    e.stopPropagation();
    const index = selectedRows.findIndex(r => r.id === rowData.id);
    { index === -1 ? setSelectedRows([...selectedRows, rowData]) : setSelectedRows([...selectedRows.filter(r => r.id !== rowData.id)]) }
  }

  const emptyRows = currentRows.length !== 0 ? currentRows.length : 0;

  return (
   
    <div className="d-flex flex-column align-items-center justify-content-center" onClick={() => handleAllToggleEvents()}>
      <div className="main-container">
      {/* Search Bar Container */}

      {/* <div className="search-bar-container gap-3" > */}

     <div className="d-flex justify-content-end m-1"> <span className="downloadLink" > Xlsx Log File </span></div>
      
        {/* <OverlayTrigger rootClose trigger="click" placement="left-start" overlay={
          <Popover>
            <Popover.Body className="overlay-body-menu">

              <ul>
                {Object.keys(selectedColumns).filter((key) => selectedColumns[key] == true).map(
                  (column) => (
                    <li key={column} onClick={() => handleFilterSelect(column)}>
                      {column.charAt(0).toUpperCase() + column.slice(1)}
                    </li>
                  )
                )}
              </ul>

            </Popover.Body>
          </Popover>
        }>
          <img
            src={filterIcon}
            alt="filter"
            className="filter-icon"
          />
        </OverlayTrigger>
        <div className="search-input-container">
          <input
            type="text"
            className="search-input"
            placeholder={`Search by ${selectedFilter || "..."}`}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            disabled={!selectedFilter} // Disable search input until a filter is selected
          />
          <img src={searchIcon} alt="search" className="search-icon" />
        </div> */}
      {/* </div> */}

      {/* Card Container */}
      <div className="border rounded border-light-subtle">
        <div className="table-container" style={{height:'200px',overflow:'auto'}}>
          <table style={{ width: "100%", whiteSpace: "nowrap" }}>
            <thead>
              <tr style={{position:'sticky',top:0}}>
                <th></th>
                {visibleColumns.map((column) => (
                  <th key={column}>
                    {column.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() + column.replace(/([A-Z])/g, " $1").slice(1)}
                    {/* <img
                      src={updownIcon}
                      alt="sort"
                      onClick={() => handleSort(column)}
                      style={{ cursor: "pointer" }}
                    /> */}
                  </th>
                ))}
                {/* <th>
                  <OverlayTrigger rootClose trigger="click" placement="auto-start" overlay={
                    <Popover>
                      <Popover.Body className="overlay-body">

                        <div>
                          <label>
                            <input
                              type="checkbox"
                              checked={selectedColumns.id}
                              disabled // Plant Name is uncheckable
                            />{" "}
                            ID
                          </label>
                          {Object.keys(selectedColumns).map(
                            (column) =>
                              column !== "id" && (
                                <label key={column}>
                                  <input
                                    type="checkbox"
                                    checked={selectedColumns[column]}
                                    onChange={() => handleColumnSelect(column)}
                                  />{" "}
                                  {column.charAt(0).toUpperCase() + column.slice(1)}
                                </label>
                              )
                          )}
                        </div>

                      </Popover.Body>
                    </Popover>
                  }>
                    <img
                      src={settingIcon}
                      alt="settings"
                      className="setting-icon"
                    />
                  </OverlayTrigger>

                </th> */}
              </tr>
            </thead>
            <tbody >
              {/* Rendering filtered rows */}

              {/* 
              <tr className={`${selectedRows.findIndex(rd => rd.id === rowdata.id) !== -1 ? 'row-select' : ''}`} key={rowdata.id} onClick={(e) => plantDetailsPage(e, rowdata)}>
              
              //Can be used in the below TR tag
              stateChanger(6)
              */}
                
              
              {currentRows.map((rowdata, index) => (
                <tr className={`${selectedRows.findIndex(rd => rd.id === rowdata.id) !== -1 ? 'row-select' : ''}`} key={rowdata.id} onClick={(e) => {stateChanger(6)}}>
                  <td className="checkbox-column">
                    <Form>
                      <Form.Check // prettier-ignore
                        type='checkbox'
                        onClick={(e) => selectRows(e, rowdata)}
                        checked={selectedRows.findIndex(rd => rd.id === rowdata.id) !== -1 ? true : false}
                      />
                    </Form>

                  </td>
                  {visibleColumns.map((column) => (
                    <td key={`${rowdata.id}-${column}`}>
                      {column === "alerts" ? (
                        <span
                          style={{
                            color:
                              rowdata.alerts === "Normal"
                                ? "blue"
                                : rowdata.alerts === "Major"
                                  ? "orange"
                                  : "red",
                          }}
                        >
                          {rowdata[column]}
                        </span>
                      ) : (
                        rowdata[column]
                      )}
                    </td>
                  ))}
                  {/* <td className="delete-column">
                   <NotInterestedOutlinedIcon onClick={(e) => { disableRow(e, rowdata) }}/>  
                  </td> */}
                </tr>
              ))}

              {/* Filling empty rows to maintain table height */}
              {emptyRows < 0 &&
                Array.from({ length: emptyRows }).map((_, index) => (
                  <tr key={`empty-${index}`}>
                    <td colSpan="9" className="empty-row"></td>
                  </tr>
                ))}
            </tbody>
          </table>
          {/* <>
          <Paginationcontrol
              rowsCount={filteredData.length}
              tableProps={tableProps}
              tablePropsChange={tablePropsChange}
              alwaysShown={true}
            />
          </> */}
        </div>
      </div>
      </div>
    </div >
  );
};

export default PlantBulkImportTable;
