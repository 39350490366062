import React, { useState, useEffect, useMemo, useContext } from "react";
import "./EntityTable.css";

// Importing icons
import updownIcon from "../../assets/updown_icon.png";
import filterIcon from "../../assets/filter-icon1.svg";
import settingIcon from "../../assets/Setting-icon.svg";
import searchIcon from "../../assets/search-icon.svg";
import editIcon from "../../assets/edit-icon.png"; // Assuming you've saved the edit icon as "edit-icon.png"
import { MessageContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { Col, Form, OverlayTrigger, Popover, Row, Modal, Stack } from "react-bootstrap";
import Paginationcontrol from "./PaginationControl";
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";

const EntityTable = ({ stateChanger, rowId }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({
    id: true,
    entityName: true,
    firstName: true,
    lastName: true,
    emailId: true,
    mobileNumber: true,
    namespace: false,
    country: false,
    state: false,
    district: true,
    pincode: false,

  });
  const [selectedFilter, setSelectedFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [selectedRows, setSelectedRows] = useState([]);
  const [update, setUpdate] = useState(false);
  const [tableProps, setTableProps] = useState({
    rowsPerPage: 5,
    currentPage: 1,
    pageFirst: 1,
    pageLast: 5,
  });

  const onMessageChange = useContext(MessageContext);
  const [showConfirmModal, setShowConfirmModal] = useState(false); // Modal visibility state
  const [selectedEntity, setSelectedEntity] = useState(null); // Selected entity for confirmation

  const navigate = useNavigate();

    // Retrieve userData from session storage
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const userEmail = userData?.email || "";
  console.log("User email from session storage:", userEmail); // Log to check email from session

  // Fetch data from the API
  const [data, setData] = useState([]);

  useEffect(() => {
    // Retrieve masterEntityId from session storage
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const entityId = userData?.entityId || "";

    // Make fetch request with masterEntityId as a query parameter
    fetch(
      `https://solar-api.antsai.in/api/entity/fetchAllRecords?entityid=${entityId}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          const formattedData = data.map((item) => ({
            id: item.id,
            entityName: item["Entity Name"],
            firstName: item["First Name"],
            lastName: item["Last Name"],
            emailId: item["Email Id"],
            mobileNumber: item["Mobile Number"],
            namespace: item["Namespace"],
            country: item["Country"],
            state: item["State"],
            district: item["District"],
            pincode: item["Pincode"],
          }));
          setData(formattedData);
        } else {
          console.error("Unexpected data format:", data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleSort = (column) => {
    let direction = "ascending";
    if (sortConfig.key === column && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: column, direction });
  };

  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = useMemo(() => {
    return sortedData.filter((row) => {
      if (!selectedFilter || !searchQuery) return true;
      return row[selectedFilter]
        .toLowerCase()
        .startsWith(searchQuery.toLowerCase());
    });
  }, [sortedData, selectedFilter, searchQuery]);

  // const disableRow = (e, rowData) => {
  //   e.stopPropagation();
  //   const index = data.findIndex((dataItem) => dataItem.id === rowData.id);
  //   rowData.status = "offline";
  //   data.splice(index, 1, rowData);
  //   setUpdate(!update);
  // };

  const navigateToEditPage = (e, rowData) => {
    e.stopPropagation();
    navigate("/admin/entity/edit", { state: { entityId: rowData.id } });
  };

  const tablePropsChange = (updatedProps) => {
    setTableProps(updatedProps);
  };

  const onRowChoose = (e, row) => {
    stateChanger(3);
    rowId(row);
  };

  const downloadCSV = () => {
    const csvRows = [];
    const headers = Object.keys(data[0] || {});
    csvRows.push(headers.join(","));

    for (const row of data) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '""'); // Escape double quotes by doubling them
        return `"${escaped}"`; // Wrap each value in quotes
      });
      csvRows.push(values.join(","));
    }

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.setAttribute("download", "entities.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // Pagination logic
  const currentRows = useMemo(() => {
    if (filteredData.length > 0) {
      return filteredData.slice(
        (tableProps.currentPage - 1) * tableProps.rowsPerPage,
        (tableProps.currentPage - 1) * tableProps.rowsPerPage +
        tableProps.rowsPerPage
      );
    }
    return [];
  }, [filteredData, tableProps]);

  const handleColumnSelect = (column) => {
    setSelectedColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  // const disableRow = async (e, entityId) => {
  //   e.stopPropagation();
  //   try {
  //     const response = await fetch(
  //       "https://solar-api.antsai.in/api/entity/disable",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
  //         },
  //         body: JSON.stringify({ entityid: entityId }), // Use lowercase 'entityid'
  //       }
  //     );

  //     if (response.ok) {
  //       alert("Entity disabled successfully.");
  //       window.location.reload(); // Refresh the page after successful disable
  //     } else {
  //       const errorData = await response.json();
  //       console.error("Failed to disable entity:", errorData);
  //       alert(
  //         `Failed to disable entity: ${errorData.message || "Unknown error"}`
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error disabling entity:", error);
  //     alert(
  //       "An error occurred while disabling the entity. Check console for details."
  //     );
  //   }
  // };

  // const disableRow = (e, entity) => {
  //   e.stopPropagation();
  //   setSelectedEntity(entity); // Store selected entity for confirmation
  //   setShowConfirmModal(true); // Show confirmation modal
  // };

  // const confirmDisable = async () => {
  //   try {
  //     const response = await fetch(
  //       "https://solar-api.antsai.in/api/entity/disable",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
  //         },
  //         body: JSON.stringify({ entityid: selectedEntity.id }),
  //       }
  //     );

  //     if (response.ok) {
  //       onMessageChange({ type: "Success", info: "Entity disabled successfully." });
  //       setShowConfirmModal(false); // Close the modal on success
  //       fetchData(); // Re-fetch data to update the table
  //     } else {
  //       const errorData = await response.json();
  //       console.error("Failed to disable entity:", errorData);
  //       onMessageChange({ type: 'Danger', info: `Failed to disable entity: ${errorData.message || "Unknown error"}` });
  //       setShowConfirmModal(false); // Close the modal in case of error
  //     }
  //   } catch (error) {
  //     console.error("Error disabling entity:", error);
  //     onMessageChange({ type: "Warning", info: "An error occurred while disabling the entity. Please try again later." });
  //     setShowConfirmModal(false); // Close the modal in case of error
  //   }
  // };

  const disableRow = (e, entity) => {
    e.stopPropagation();
    setSelectedEntity(entity); // Store selected entity for confirmation
    setShowConfirmModal(true); // Show confirmation modal
  };

  const confirmDisable = async () => {
    try {
      const response = await fetch(
        "https://solar-api.antsai.in/api/entity/disable",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
          },
          body: JSON.stringify({ entityid: selectedEntity.id }),
        }
      );

      if (response.ok) {
        onMessageChange({ type: "Success", info: "Entity disabled successfully." });
        setShowConfirmModal(false); // Close the modal on success
        // Add a delay before reloading to allow the success message to show
        setTimeout(() => {
          window.location.reload();
        }, 1000); // 1-second delay
      } else {
        const errorData = await response.json();
        console.error("Failed to disable entity:", errorData);
        onMessageChange({ type: 'Danger', info: `Failed to disable entity: ${errorData.message || "Unknown error"}` });
        setShowConfirmModal(false); // Close the modal in case of error
      }
    } catch (error) {
      console.error("Error disabling entity:", error);
      onMessageChange({ type: "Warning", info: "An error occurred while disabling the entity. Please try again later." });
      setShowConfirmModal(false); // Close the modal in case of error
    }
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-container">
        <Row className="d-flex flex-row align-items-center justify-content-between my-4">

          <Col></Col>
          <Col className="gap-3 d-flex flex-row align-items-center justify-content-end" >
            <span className="downloadLink" onClick={downloadCSV}>
              Download
            </span>
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="left-start"
              overlay={
                <Popover>
                  <Popover.Body className="overlay-body-menu">
                    <ul>
                      {Object.keys(selectedColumns)
                        .filter((key) => selectedColumns[key])
                        .map((column) => (
                          <li
                            key={column}
                            onClick={() => setSelectedFilter(column)}
                          >
                            {column.charAt(0).toUpperCase() + column.slice(1)}
                          </li>
                        ))}
                    </ul>
                  </Popover.Body>
                </Popover>
              }
            >
              <img src={filterIcon} alt="filter" className="filter-icon" />
            </OverlayTrigger>

            <div style={{ position: 'relative' }} className=" w-auto">
              <input
                type="text"
                style={{
                  width: '341px',
                  height: '39px',
                  background: '#BBCCD9 0% 0% no-repeat padding-box',
                  borderRadius: '5px',
                  opacity: ' 0.51'
                }}
                className="search-input"
                placeholder={`Search by ${selectedFilter || "..."}`}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                disabled={!selectedFilter}
              />
              <img src={searchIcon} alt="search" className="search-icon" />
            </div>
          </Col>
        </Row>
        <div className="border rounded border-light-subtle">
          <div className="table-container" >
            <table>
              <thead>
                <tr>
                  {/* <th></th> */}
                  {Object.keys(selectedColumns)
                    .filter((key) => selectedColumns[key])
                    .map((column) => (
                      <th key={column} onClick={() => handleSort(column)}>
                        {column === "id" ? "Entity ID" : column.replace(/([A-Z])/g, " $1").replace(/^\w/, (c) => c.toUpperCase())}
                        <img
                          src={updownIcon}
                          alt="sort"
                          style={{ cursor: "pointer" }}
                        />
                      </th>
                    ))}
                  {/* <th></th> Empty th for edit icon column */}
                  <th>
                    <div className="gap-2 d-flex flex-row align-items-center justify-content-end">
                      <OverlayTrigger
                        rootClose
                        trigger="click"
                        placement="auto-start"
                        overlay={
                          <Popover>
                            <Popover.Body className="overlay-body">
                              <div>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={selectedColumns.id}
                                    disabled
                                  />{" "}
                                  Entity ID
                                </label>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={selectedColumns.entityName}
                                    disabled
                                  />{" "}
                                  Entity Name
                                </label>
                                {Object.keys(selectedColumns).map(
                                  (column) =>
                                    column !== "id" &&
                                    column !== "entityName" && (
                                      <label key={column}>
                                        <input
                                          type="checkbox"
                                          checked={selectedColumns[column]}
                                          onChange={() =>
                                            handleColumnSelect(column)
                                          }
                                        />{" "}
                                        {column.charAt(0).toUpperCase() +
                                          column.slice(1)}
                                      </label>
                                    )
                                )}
                              </div>
                            </Popover.Body>
                          </Popover>
                        }
                      >

                        {/* <div></div> */}
                        <div><img
                          src={settingIcon}
                          alt="settings"
                          className="setting-icon"
                        />
                        </div>

                      </OverlayTrigger>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentRows.map((row) => (
                  <tr key={row.id}>
                    {/* <td>
                      <Form.Check type="checkbox" />
                    </td> */}
                    {Object.keys(selectedColumns)
                      .filter((key) => selectedColumns[key])
                      .map((column) => (
                        <td key="{${row.id}-${column}}"
                        //  style={!isNaN(Number(row[column])) ? { 'text-align': 'center' } : { 'text-align': 'left' }} 
                        >
                          {row[column]}</td>
                      ))}
                    {/* <td className="edit-icon-cell">
                      <img
                        src={editIcon}
                        alt="edit"
                        className="edit-icon"
                        onClick={(e) => onRowChoose(e, row.id)}
                      />
                    </td>
                    <td>
                       <NotInterestedOutlinedIcon
                        onClick={(e) => disableRow(e, row)} // Pass the row object
                        style={{ cursor: "pointer" }}
                      />
                    </td> */}
                    <td className="delete-column">
                      <div className="gap-2 d-flex flex-row align-items-center justify-content-end">
                        <img
                          src={editIcon}
                          alt="edit"
                          className="edit-icon"
                          onClick={(e) => onRowChoose(e, row.id)}
                        />
                        {/* <NotInterestedOutlinedIcon
                          onClick={(e) => disableRow(e, row)} // Pass the row object
                          style={{ cursor: "pointer" }}
                        /> */}

                         {/* Conditionally render disable button */}
                         {/* {row.emailId !== userEmail && (
                          <NotInterestedOutlinedIcon
                            onClick={(e) => disableRow(e, row)}
                            style={{ cursor: "pointer" }}
                          />
                        )} */}
  {row.emailId !== userEmail ? (
            <NotInterestedOutlinedIcon
              onClick={(e) => disableRow(e, row)}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <span style={{ width: "24px" }}></span> // Placeholder for alignment
          )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {filteredData.length > 0 ? <Paginationcontrol
              rowsCount={filteredData.length}
              tableProps={tableProps}
              tablePropsChange={tablePropsChange}
              alwaysShown={true}
            /> : <></>}
          </div>
        </div>
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center">
        {/* Confirmation Modal */}
        <Modal
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Disable Entity ?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to disable the entity "{selectedEntity?.entityName}"?
          </Modal.Body>
          <Modal.Footer>
            <button
              className="modal-button"
              onClick={() => setShowConfirmModal(false)}
              autoFocus // "No" as default focus
            >
              No
            </button>
            <button className="modal-button" onClick={confirmDisable}>
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default EntityTable;